


.nav-link {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8) !important;
  }
  
  .social-nav .nav-link {
    padding: 0 0.5rem;
    font-size: 1.1rem;
    line-height: 2.5rem;
  }
  
  .nav-link:hover,
  .nav-link:focus,
  .active .nav-link {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  
  /* Hero (intro) section */
  .intro-title {
    font-weight: 800;
  }
  .demo{
    height: 2vh;
  }
  
 
  .btn {
    font-size: .9375rem;
}
.btn-primary {
    color: #5c5656;
    background-color: #f6e05e;
}



#header-nav.fixed-top {
  padding-top: 0;
  padding-bottom: 0;
}

#header-nav.fixed-top {
  position: fixed;
  background: #fff!important;}

  #header-nav {
    transition: all .5s ease;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  /* Additional styles for the sticky header */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}




.header.sticky .logo,.header.sticky ul li a{color: #000};
























