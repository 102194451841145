

.text-center {
    text-align: center !important;
}


.py-1{
    margin-right: 25px;}

.row{margin-left: 10px;}    