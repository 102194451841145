@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-size: auto;
  
   
    

}


html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  background: color #fcfbfc;
  overflow-x: hidden;
}

:root {
  --primary-color: #379cf6;
  --heading-color: #fff;
  --btn-primary-bg: #379cf6;
  --btn-secondary-bg: #090238;
  --team-card-bg:	#f8f8f8;
  --link-color: #fff;
  --link-active: #379cf6;
  --card-bg: linear-gradient(
    45deg,
    rgba(15, 18, 61, 1) 33%,
    rgba(15, 18, 61, 1) 52%,
    rgb(15, 20, 26) 84%
  );
  --counter-color: linear-gradient(
    90deg,
    #16a1c4 30%,s
    rgba(29, 129, 176, 1) 55%,
    rgba(35, 93, 173, 1) 75%
  );
  --body-bg: rgba(6, 11, 26, 1);
  --newsletter-bg: linear-gradient(
    90deg,
    rgba(27, 9, 115, 1) 20%,
    rgba(23, 9, 96, 1) 38%,
    rgba(14, 9, 56, 1) 100%
  );
  --testimonial-bg: #1a0f4f;
  --font-name: "Poppins", sans-serif;
  --small-text-color: rgb(59, 59, 40);
  --icon-bg:#151e4f;
}

.light-theme {
  --body-bg: rgb(224, 105, 105);
  --heading-color: #0c123d;
  --btn-secondary-bg: #816aff;
  --btn-primary-bg: #fff;
  --primary-color: #816aff;
  --counter-color: #fff;
  --counter-section-bg: #816aff;
  --link-color: #0c123d;
  --link-active: #816aff;
  --newsletter-bg: linear-gradient(
    180deg,
    rgba(189, 243, 255, 1) 29%,
    rgba(193, 243, 255, 1) 50%,
    rgba(226, 250, 255, 1) 78%
  );
  --small-text-color:#000;
  --card-bg:#fff;
  --testimonial-bg: #f7f7f7;
  --team-card-bg: #fff;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background: var(--body-bg);
  font-family: var(--font-name);
}

a{
  text-decoration: none;
  color: var(--link-color);
}

h2 {
  color: var(--heading-color);
  font-size: 2rem;
}

section{
  padding: 60px 0px;
}

.container {
  width: 1140px;
  padding: 0px 15px;
  margin: auto;
}

.description {
  color: var(--small-text-color);
  line-height: 30px;
  font-size: .9rem;
}

.subtitle{
  font-size: 1rem;
  color: var(--primary-color);
  font-weight: 400;
  margin-bottom: 20px;
}


@media only screen and (max-width: 1024px) {
  .container{
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
section{
    padding: 40px 0px;
  }
}
.text-center{
  text-align: center !important;
}


